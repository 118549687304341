import React from 'react';
import { Link } from 'gatsby';

import './navigationmenu.style.scss';

const NavigationMenu = ({ menuState, setMenuState }) => {
  return (
    <nav className='navigation__menu--primary animate__animated animate__fadeInRight'>
      <ul>
        <li className='menu__item'>
          <Link
            title='Home'
            to='/'
            onClick={() => setMenuState(false)}
            activeClassName='menu__item--current'
          >
            Home
          </Link>
        </li>

        <li className='menu__item'>
          <Link
            to='/services/'
            onClick={() => setMenuState(false)}
            activeClassName='menu__item--current'
          >
            Services
          </Link>
        </li>

        <li className='menu__item'>
          <Link
            to='/works/'
            onClick={() => setMenuState(false)}
            activeClassName='menu__item--current'
          >
            Works
          </Link>
        </li>

        <li className='menu__item'>
          <Link
            to='/contact/'
            onClick={() => setMenuState(false)}
            activeClassName='menu__item--current'
          >
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavigationMenu;
