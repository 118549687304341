import * as React from 'react';

import './menusecondary.style.scss';

const MenuSecondary = () => (
  <nav className='menu--secondary'>
    <ul>
      <li>
        <a
          href='https://twitter.com/innovight/'
          className='btn transform-scale-h border-0 p-0'
          rel='noopener noreferrer'
          target='_blank'
        >
          Twitter<span className='dot'>.</span>
        </a>
      </li>
      <li>
        <a
          href='https://instagram.com/innovight/'
          className='btn transform-scale-h border-0 p-0'
          rel='noopener noreferrer'
          target='_blank'
        >
          Instagram<span className='dot'>.</span>
        </a>
      </li>
      <li>
        <a
          href='https://behance.com/innovight/'
          className='btn transform-scale-h border-0 p-0'
          rel='noopener noreferrer'
          target='_blank'
        >
          Behance<span className='dot'>.</span>
        </a>
      </li>
    </ul>
  </nav>
);

export default MenuSecondary;
