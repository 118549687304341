import * as React from 'react';
import MenuSecondary from '../menusecondary';

import './footer.style.scss';

const Footer = () => (
  <footer id='footer' className='site-footer'>
    <div className='wrapper'>
      <div>
        <MenuSecondary />
      </div>
      <div className='site-footer__copyright'>
        <p>© {new Date().getFullYear()} innovight</p>
      </div>
    </div>
  </footer>
);

export default Footer;
