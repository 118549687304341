import React from 'react';
import { Link } from 'gatsby';

import Logo from '../../images/svg/innovight-logo--white.svg';
import './navigationlogo.style.scss';

const NavigationLogo = () => (
  <Link title='Logo' className='transform-scale-h' to='/'>
    <img
      src={Logo}
      alt='Innovight favicon logo v with 2 dots and a circle'
      className='navigation__logo'
    />
  </Link>
);

export default NavigationLogo;
