import React, { useEffect } from 'react';
import { Link } from 'gatsby';

import favicon from '../../images/svg/favicon.svg';
import './header.style.scss';
import MenuButton from '../menubutton';

const Header = ({ menuState, setMenuState }) => {
  useEffect(() => {
    setMenuState(false);
  }, [setMenuState]);

  return (
    <header id='header' className='header'>
      <Link
        title='Innovight Home'
        className='transform-scale-h header__logo__link'
        to='/'
      >
        <img
          src={favicon}
          alt='Innovight favicon logo v with 2 dots and a circle'
          className='header__logo'
        />
      </Link>
      <MenuButton setMenuState={setMenuState} menuState={menuState} />
    </header>
  );
};

export default Header;
