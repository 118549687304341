import React from 'react';
import NavigationMenu from '../navigationmenu';
import NavigationLogo from '../navigationlogo';
import MenuSecondary from '../menusecondary';
import Video from '../../video/video.mp4';
import './navigation.style.scss';

const Navigation = ({ menuState, setMenuState }) => {
  return (
    <>
      {menuState && (
        <div
          className='
          modal
          animate__animated animate__fadeIn animate__faster
        '
          id='menu-modal'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='menu-modal'
          aria-hidden='true'
        >
          <div className='modal__full' role='document'>
            <div className='wrapper'>
              <div className='modal__full__content'>
                <video id='background-video' autoPlay loop muted>
                  <source src={Video} type='video/mp4' />
                </video>
                <div className='modal__full__content__header animate__animated animate__backInDown'>
                  <NavigationLogo />

                  <button
                    type='button'
                    className='
                    close
                    transform-scale-h
                  '
                    data-dismiss='modal'
                    aria-label='Close'
                    onClick={() => setMenuState(false)}
                  >
                    <span aria-hidden='true'>
                      <span>Close</span>
                    </span>
                  </button>
                </div>

                <div className='modal__full__content__body'>
                  <NavigationMenu
                    setMenuState={setMenuState}
                    menuState={menuState}
                  />
                </div>
                <div className='modal__full__content__navigation--secondary animate__animated animate__backInUp'>
                  <MenuSecondary />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navigation;
