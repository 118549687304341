import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Header from '../components/header';
import Navigation from '../components/navigation';
import Footer from '../components/footer';

const Layout = ({ children }) => {
  const [menuState, setMenuState] = useState(false);
  React.useEffect(() => {
    let body = document.getElementsByTagName('body')[0];

    if (menuState) body.style.overflow = 'hidden';
    else body.style.overflow = 'auto';
  });

  return (
    <>
      <Header menuState={menuState} setMenuState={setMenuState} />
      <Navigation menuState={menuState} setMenuState={setMenuState} />
      <div>
        <div>{children}</div>
        <Footer />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
